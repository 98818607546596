import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./component/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./pages/component/Footer";
import PaymentPage from "./pages/PaymentPage";
import CartSidebar from "./pages/component/Cart";
import { useCart } from "./context/CartContext";

function App() {
  const { cart, isCartOpen, toggleCart } = useCart();
  return (
    <Router>
      <Header />
      <main className="">
        {isCartOpen && <CartSidebar handleClose={toggleCart} />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payment" element={<PaymentPage />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
