import React from "react";

const ContactForm = () => {
  return (
    <div className=" bg-red-800 text-white px-14 py-12 box-border items-center flex flex-col md:flex-row gap-4 justify-between">
      <div className="w-full md:w-1/2">
        <h2 className="text-2xl font-bold mb-4">CONTACT US</h2>
        <p>siditallc@gmail.com</p>
        <p>6788992729        </p>
      </div>
      <form className="grid grid-cols-2 gap-4 mt-4 w-full md:w-1/2">
        <div>
          <label className="block">Name *</label>
          <input
            type="text"
            className="w-full p-2 rounded text-black border-b-2 border-white bg-transparent focus:outline-none"
            placeholder="Enter your name"
          />
        </div>
        <div>
          <label className="block">Email *</label>
          <input
            type="email"
            className="w-full p-2 rounded text-black border-b-2 border-white bg-transparent focus:outline-none"
            placeholder="Enter your email"
          />
        </div>
        <div>
          <label className="block">Phone</label>
          <input
            type="tel"
            className="w-full p-2 rounded text-black border-b-2 border-white bg-transparent focus:outline-none"
            placeholder="Enter your phone number"
          />
        </div>
        <div>
          <label className="block">Address</label>
          <input
            type="text"
            className="w-full p-2 rounded text-black border-b-2 border-white bg-transparent focus:outline-none"
            placeholder="Enter your address"
          />
        </div>
        <div className="col-span-2">
          <label className="block">Subject</label>
          <input
            type="text"
            className="w-full p-2 rounded text-black border-b-2 border-white bg-transparent focus:outline-none"
            placeholder="Type the subject"
          />
        </div>
        <div className="col-span-2">
          <label className="block">Message</label>
          <textarea
            className="w-full p-2 rounded text-black border-b-2 border-white bg-transparent focus:outline-none"
            placeholder="Type your message here..."
          ></textarea>
        </div>
        <div className="col-span-2">
          <button
            type="submit"
            className="bg-white text-blue-800 py-2 px-4 rounded w-full"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
