import React, { useState } from "react";
import CartPopup from "./component/CartPopup";
import CartSidebar from "./component/Cart";
import { useCart } from "../context/CartContext";

const quickView = [
  { title: "1 Day Immobilization", price: 75.0 },
  { title: "2 Day Immobilization", price: 150.0 },
  { title: "3 Day Immobilization", price: 225.0 },
  { title: "4 Day Immobilization", price: 300.0 },
  { title: "5 Day Immobilization", price: 375.0 },
  { title: "6 Day Immobilization", price: 450.0 },
  { title: "7 Day Immobilization", price: 525.0 },
];

const PaymentPage = () => {
  const [isOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { cart, addToCart } = useCart();

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  const handleAddToCart = (product, quantity, details, fullName) => {
    addToCart({ ...product, quantity, details, fullName });
    closeModal();
  };

  return (
    <div className="text-center px-8 py-12">
      {isOpen && selectedProduct && (
        <CartPopup
          title={selectedProduct.title}
          price={selectedProduct.price}
          handleClose={closeModal}
          onAddToCart={handleAddToCart}
        />
      )}
      <h1 className="text-3xl font-bold mb-6">Pay Your Immobilization Fee</h1>
      <p className="mb-4">
        Please Call 4703844013 To Confirm Your Fee Amount
      </p>
      <p className="mb-4 text-red-600">
        ** The ENTIRE Fee Amount Must Be Paid To Remove The Immobilization
        Device **
      </p>

      <div className="my-8">
        <h2 className="text-2xl font-bold mb-4">Mobile Payment</h2>
        <p className="mb-4">
          Please Enter Your License Plate #, Make, Model, & Color In The Payment
          Description
        </p>
        <p className="mb-4">
          After Your Mobile Payment Is Sent Please Contact{" "}
          <strong>4703844013</strong> To Confirm Your Transaction : Please
          Give Them Your Transaction Code.
        </p>
        <p className="text-red-600 mb-4">
          YOUR VEHICLE WILL NOT BE RELEASED WITHOUT A TRANSACTION CODE
        </p>
        <div className="flex justify-around items-center flex-col md:flex-row gap-4">
          <div className="text-center">
            <img
              src="/p1.png"
              alt="CashApp"
              className="mx-auto mb-2 w-52 h-52"
            />
            <p><span className=" font-bold">$Siditallc</span></p>
          </div>
          <div className="text-center">
            <img src="/zelle.png" alt="Zelle" className="mx-auto mb-2 w-44 h-44" />
            <p className="font-bold"> 6788992729</p>
          </div>
          
        </div>
      </div>

      <div className="my-8">
        <h2 className="text-2xl font-bold mb-4">Pay By Card</h2>
        <p className="mb-4">
          We are currently not accepting online payments at this time. Please
          complete your transaction through Mobile Payment or if you wish to pay
          by card, <strong>Complete Your Transaction in Person.</strong>
        </p>
        <p className="mb-4">
          We only accept Visa & Mastercard.{" "}
          <span className="text-red-600">
            We DO NOT accept American Express or Discover Cards.
          </span>
        </p>
        <div className="grid flex-1 grid-cols-1 bg-slate-100 p-2 sm:grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4">
          {quickView.map((item, index) => {
            return (
              <div key={index} className="bg-white p-1 shadow">
                <img
                  src="/logo.png"
                  alt={item.title}
                  className="mx-auto mb-2 w-40 h-40"
                />
                <p>{item.title}</p>
                <p>${item.price.toFixed(2)}</p>
                <button
                  onClick={() => {
                    setSelectedProduct(item);
                    setModalOpen(true);
                  }}
                  className="bg-black w-full text-white py-2 px-4 rounded mt-2"
                >
                  Add to Cart
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
