import React from "react";
import { useCart } from "../context/CartContext";
import { BiCart } from "react-icons/bi";
const Header = () => {
  const { cart, toggleCart } = useCart();
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center">
        <img src="/logo.png" className=" w-40 h-40" alt="logo" />
        <p className="text-sm text-gray-400">Pay Your Immobilization Fee</p>
        <button onClick={toggleCart} className="relative ">
          <BiCart className="w-10 h-10" />
          {cart.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
              {cart.length}
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default Header;
