import React from "react";
import PaymentOption from "../component/ItemCard";
import ContactForm from "./component/contact";

const Home = () => {
  return (
    <main className="px-6 py-12">
      <h2 className="text-3xl font-bold text-center mb-4">REMOVE A BOOT</h2>
      <p className="text-center mb-6">
        Please Select The Duration Of Time Your Vehicle Has Been Immobilized
      </p>
      <p className="text-center mb-6">
        Please Call 4703844013        If You Are Unsure Of Your Fee Amount
      </p>
      <p className="text-center text-gray-600 mb-6">
        The ENTIRE Fee Amount Must Be Paid To Remove The Immobilization Device
      </p>
      <div className="flex flex-col items-center justify-center mb-4 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 flex-1 my-8">
          <PaymentOption
            price={75}
            duration="1 Day"
            description="My vehicle has been immobilized for less than 24 hours."
          />
          <PaymentOption
            price={150}
            duration="2 Day"
            description="My vehicle has been immobilized for 24-48 hours."
          />
          <PaymentOption
            price={225}
            duration="3 Day"
            description="My vehicle has been immobilized for 48-72 hours."
          />
        </div>
        <button className="border border-blue-600 p-2.5 rounded-lg">
          More payment options
        </button>
      </div>
      <ContactForm />
    </main>
  );
};

export default Home;
