import React from "react";

const Footer = () => {
  return (
    <footer className=" text-black text-center p-4  ">
      <h2 className="text-xl">Sidita LLC.</h2>
      <p className="text-sm font-semibold">Parking Enforcement Services</p>
      <p>4703844013</p>
      <p>22C2 Bennett Street, Suite A Atlanta, Georgia 30309</p>
      <p className="mt-6">©2024 by Artisan Horizons Studio</p>
    </footer>
  );
};

export default Footer;
