import React, { useState } from "react";

const CartPopup = ({ title, price, handleClose, onAddToCart }) => {
  const [quantity, setQuantity] = useState(1);
  const [details, setDetails] = useState("");
  const [fullName, setFullName] = useState("");

  const handleAddToCart = () => {
    onAddToCart({ title, price, quantity, details, fullName });
  };

  return (
    <div className="h-full w-full fixed inset-0 bg-[rgba(0,0,0,0.6)] flex items-center justify-center">
      <div
        onClick={(e) => e.stopPropagation()}
        className="space-y-3 p-5 bg-white w-[90%] max-w-[527px] rounded-[5px] shadow-md"
      >
        <div className="flex justify-between items-start">
          <img
            src="/logo.png"
            alt="logo"
            className="w-24 h-24 lg:w-52 lg:h-52"
          />
          <button className="text-xl font-bold" onClick={handleClose}>
            ✕
          </button>
        </div>
        <div className="text-center">
          <h2 className="text-2xl font-bold">{title}</h2>
          <p className="text-lg mt-2">${price.toFixed(2)}</p>
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium mb-1">
            Please Enter Your License Plate #, Make, Model, Color
          </label>
          <textarea
            className="w-full h-24 p-2 border rounded"
            placeholder="Enter details here..."
            maxLength={500}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium mb-1">
            Please Enter Your Full Legal Name & State ID/Drivers License #
          </label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            placeholder="Enter your name and ID..."
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className="mt-4 text-center">
          <button
            onClick={handleAddToCart}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Add to Cart
          </button>
        </div>
        <div className="mt-2 text-center">
          <a href="#" className="text-sm text-blue-500">
            View More Details
          </a>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;
