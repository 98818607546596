import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentOption = ({ price, duration, description }) => {
  const navigate = useNavigate();

  const handlePayNowClick = () => {
    navigate("/payment");
  };

  return (
    <div className="bg-white shadow-md p-6 rounded-lg text-center">
      <div className="text-2xl font-bold">${price}</div>
      <div className="text-lg">{duration} Immobilization</div>
      <p className="text-gray-600">{description}</p>
      <button
        className="bg-blue-500 text-white mt-4 py-2 px-4 rounded"
        onClick={handlePayNowClick}
      >
        Pay Now
      </button>
    </div>
  );
};

export default PaymentOption;
