import React from "react";
import { useCart } from "../../context/CartContext";

const CartSidebar = ({ handleClose }) => {
  const { cart, removeFromCart } = useCart();

  return (
    <div className="fixed top-0 right-0 w-80 h-full bg-white shadow-lg z-50">
      <div className="flex justify-between items-center p-4 bg-gray-800 text-white">
        <h2 className="text-xl">Your Cart</h2>
        <button onClick={handleClose} className="text-xl font-bold">
          ✕
        </button>
      </div>
      <div className="p-4">
        {cart.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <ul>
            {cart.map((item, index) => (
              <li key={index} className="mb-4 border-b pb-4">
                <h3 className="text-lg font-bold">{item.title}</h3>
                <p>Price: ${item.price.toFixed(2)}</p>
                <p>Quantity: {item.quantity}</p>
                <p>Details: {item.details}</p>
                <p>Name: {item.fullName}</p>
                <button
                  onClick={() => removeFromCart(index)}
                  className="bg-red-500 text-white py-1 px-2 rounded mt-2"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CartSidebar;
